<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";

//import { tableData } from "@/views/pages/tables/dataAdvancedtable";
import { tableData } from "@/views/pages/tables/dataRevenue";
import revenueChartView from "./revenueChartView.vue";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Revenue",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, revenueChartView },
  data() {
    return {
      tableData: tableData,
      title: "Revenue",
      items: [
        {
          text: "Tables",
          href: "/"
        },
        {
          text: "",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true },
        { key: "position", sortable: true },
        { key: "office", sortable: true },
        { key: "age", sortable: true },
        { key: "date", sortable: true },
        { key: "salary", sortable: true }
      ],
      setMonth: '',
      setYear:'',
      daterange:'',
      startDate:"",
      endDate:"", 
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.setDate();
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setDate(){
      var date = new Date();
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // var startDate = date.setDate(firstDay.getDate());
      this.startDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-01';
      this.endDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();

      //console.log(this.endDate);
    },
    setStartDate(e){
        console.log(e);
        this.startDate = e;
    },
    setEndDate(e){
        console.log(e);
        this.endDate = e;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <div class="row">
      <div class="col-xl-2">
      <div class="form-group mb-3">
                <label>Start Date</label>
                <br />
                <date-picker v-model="startDate" format="Y-M-DD" value-type="format" :first-day-of-week="1" lang="en" placeholder="Select Date" @change="setStartDate"></date-picker>
              </div>
      </div>
      <div class="col-xl-3">
      <div class="form-group mb-3">
                <label>End Date</label>
                <br />
                <date-picker v-model="endDate" format="Y-M-DD" value-type="format" :first-day-of-week="1" lang="en" placeholder="Select Date" @change="setEndDate"></date-picker>
              </div>
      </div>
      <div class="col-xl-2">
        <button type="button" class="btn btn-primary btn-sm" style="margin-top :2rem" @click.prevent="filterDate">Filter</button>
      </div>       
    </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <!-- <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div> -->
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table striped hover
                :items="tableData"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :head-variant="headVariant"
              ></b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <revenueChartView />
      </div>
    </div>
  </Layout>
</template>