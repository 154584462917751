// const tableData = [
//     {
//         24:[200,40,10],
//         23:[300,20,15],
//         22:[400,25,25],
//         21:[500,30,20],
//         20:[250,40,25],
//         des:['Impression', 'Click', 'CTR']
//     }
// ];

const tableData = [
    {
        des:'Impression',
        31:1000,
        30:400,
        29:500,
        28:400,
        27:500,
        26:300,
        25:400,
        24:600,
        23:650,
        22:700,
        21:400,
        20:500,
        19:700,
        18:400,
        17:300,
        16:450,
        15:700,
        14:300,
        13:500,
        12:200,
        11:600,
        10:400,
        9:300,
        8:250,
        7:450,
        6:300,
        5:400,
        4:600,
        3:500,
        2:400,
        1:200
    },
    {
        des:'Click',
        31:100,
        30:40,
        29:50,
        28:40,
        27:50,
        26:30,
        25:40,
        24:60,
        23:65,
        22:70,
        21:40,
        20:50,
        19:70,
        18:40,
        17:30,
        16:45,
        15:70,
        14:30,
        13:50,
        12:20,
        11:60,
        10:40,
        9:30,
        8:25,
        7:45,
        6:30,
        5:40,
        4:60,
        3:50,
        2:40,
        1:20
    },
    {
        des:'CTR',
        31:'50.00',
        30:'10.00',
        29:'10.00',
        28:'10.00',
        27:'10.00',
        26:'10.00',
        25:'10.00',
        24:'10.00',
        23:'23.50',
        22:'20.50',
        21:'11.52',
        20:'20.50',
        19:'10.00',
        18:'10.00',
        17:'10.50',
        16:'11.20',
        15:'12.50',
        14:'11.10',
        13:'12.50',
        12:'13.50',
        11:'10.00',
        10:'12.00',
        9:'10.00',
        8:'10.00',
        7:'12.00',
        6:'13.00',
        5:'14.00',
        4:'15.00',
        3:'11.00',
        2:'12.00',
        1:'15.50'
    },
    

];

export { tableData };
