<script>
/**
 * Revenue Analytics component
 */
export default {
  data() {
    return {
      series: [{
        name: "Click",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
    },
    {
        name: "CTR",
        data: [50.56, 92.85, 51.35, 24.48, 45.83, 68.42, 32.81, 43.47, 7.14, 13.79, 32.60, 20.40]
    },
    {
        name: 'Impression',
        data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
    }],
    chartOptions: {
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },
        colors: ['#556ee6', '#f46a6a', '#34c38f'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [3, 4, 3],
            curve: 'straight',
            dashArray: [0, 8, 5]
        },
        title: {
            text: '',
            align: 'left'
        },
        markers: {
            size: 0,

            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: ['23 Dec', '24 Dec', '25 Dec', '26 Dec', '27 Dec', '28 Dec', '29 Dec', '30 Dec', '31 Dec',
                '01 Jan', '02 Jan', '03 Jan'
            ],
        },
        tooltip: {
            y: [{
                title: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }, {
                title: {
                    formatter: function (val) {
                        return val + " (%) ";
                    }
                }
            }, {
                title: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }]
        },
        grid: {
            borderColor: '#f1f1f1',
        }
    }
    }
  }
};  
</script>

<template>
  <div class="card">
    <div class="card-body">
      
      <h4 class="card-title mb-4">Revenue Statistics</h4>
            <apexchart
              class="apex-charts"
              height="380"
              type="line"
              dir="ltr"
              :series="series"
              :options="chartOptions"
            ></apexchart>
      </div>
    </div>
</template>